import {List} from 'immutable'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './modalHeaders.scss'


export const ModalHeader = ({banner, className, heading, note, subheading, ...otherProps}) => (
  <header {...otherProps} className={classnames('modal-header', className)}>
    {banner && <div className='modal-banner'>{banner}</div>}
    <h2>{heading}</h2>
    {subheading && <div className='subheading'>{subheading}</div>}
    {note && <div className='modal-note' data-testid='modal-note'>{note}</div>}
  </header>
)

export const ModalHeaderWithChildren = ({className, children, ...otherProps}) => (
  <header {...otherProps} className={classnames('modal-header', className)}>
    {children}
  </header>
)

ModalHeader.defaultProps = {
  banner: '',
  heading: ''
}

ModalHeader.propTypes = {
  banner: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.instanceOf(List), PropTypes.string]), // KeyFields are Lists and can be passed in as a heading
  note: PropTypes.string,
  subheading: PropTypes.string
}

export const ModalHeaderBottomDivider = props => <ModalHeader {...props} className={classnames('bottom-divider', props.className)} />
export const ModalHeaderWithChildrenAndBottomDivider = props => <ModalHeaderWithChildren {...props} className={classnames('bottom-divider', props.className)} />
